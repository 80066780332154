button:focus {
  outline: none;
}

.bg-gradient{
  background-image: linear-gradient(261.92deg, #484FFF -26.08%,  #F53356 123.74%) !important;
}

.btn-gradient {
  background-image: linear-gradient(261.92deg, #484FFF -26.08%,  #F53356 123.74%) !important;
  box-shadow: 0px 6px 12px rgba(192, 231, 253, 0.5);
  border: none;
}

.text-gradient{
  background: -webkit-linear-gradient(#484FFF -26.08%,  #F53356 123.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.personal__info label {
  font-size: 13px;
  font-weight: 600;
}
